import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import CommonEn from './resources/en/common.json'
import CommonRu from './resources/ru/common.json'
import CommonHy from './resources/hy/common.json'
import DashboardEn from './resources/en/dashboard.json'
import DashboardRu from './resources/ru/dashboard.json'
import DashboardHy from './resources/hy/dashboard.json'
import detector from 'i18next-browser-languagedetector'

const resources = {
  en: {
    translation: CommonEn,
    dashboard: DashboardEn,
  },
  ru: {
    translation: CommonRu,
    dashboard: DashboardRu,
  },
  hy: {
    translation: CommonHy,
    dashboard: DashboardHy,
  },
}

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    whitelist: ['hy', 'en', 'ru'],
    lng: 'hy',
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
  })
export default i18n
