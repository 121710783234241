import React, { useState } from 'react'
import InputRowEmail from './InputRow/InputEmail/InputEmail'
import InputRowPassword from './InputRow/InputPassword/InputPassword'
import CheckboxRow from './CheckboxRow/Checkbox'
import RadioButtonSingleRow from './RadioButtonRow/RadioButtonSingle'
import SubmitButton from './SubmitButton'
import Panel from '../../../Layout/Panel/Panel'
import Breadcrumbs from '../Breadcrumbs'
import Dialog from '../Dialog'
import { useTranslation } from 'react-i18next'
import api, { parseError, parseErrors } from '../../../Api'
import RightModal from '../RightModal'
import Accordion from '../Accordion'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import LanguageLink from '../../../I18n/LanguageLink'
function Form() {
  const { t, i18n } = useTranslation()
  const [buttonStyle, setbuttonStyle] = useState({
    text: 'Submit',
    plus: 'plus',
  })
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    message: 'Message',
    agree: '',
    name: 'radioName',
    checkboxId: 'checkboxId',
    radioId: {
      id1: 'radioId1',
      id2: 'radioId2',
      checked1: true,
      checked2: false,
    },
  })
  const breadcrumbsLink = [
    {
      title: 'Dashboard',
      link: '/dashboard',
      style: '',
    },
    {
      title: 'Form',
      link: '/form',
      style: '',
    },
  ]
  const [buttonActive, setButtonActive] = useState<boolean>(true)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const input = event.currentTarget
    setFormData({
      ...formData,
      [input.name]: input.type === 'radio' ? input.checked : input.value,
    })
  }
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()

    if (!formData.agree) {
      setErrors({ agree: 'Please agree with terms.' })
      return
    }

    setButtonActive(false)
    setErrors({})
    setError(null)
    setSuccess(null)

    api
      .post('/v1/auth/join', {
        email: formData.email,
      })
      .then(() => {
        setSuccess('Confirm join by link in email.')
        setButtonActive(true)
      })
      .catch(async (error) => {
        setErrors(await parseErrors(error))
        setError(await parseError(error))
        setButtonActive(true)
      })
  }
  const items = [
    {
      title: 'Section 1',
      content: 'Content for Section 1 goes here.',
    },
    {
      title: 'Section 2',
      content: 'Content for Section 2 goes here.',
    },
    {
      title: 'Section 3',
      content: 'Content for Section 3 goes here.',
    },
  ]
  return (
    <Panel>
      <h2>{t('prod_title')}</h2>
      <Breadcrumbs>
        {breadcrumbsLink.map((item, index) => (
          <LanguageLink to={item.link} key={index}>
            {item.title}
          </LanguageLink>
        ))}
      </Breadcrumbs>
      <Dialog>
        <div className="dialogContent">
          <img src="/images/alert.svg" className="dialogIcon" />
          <p className="dialogText">{t('question')}</p>
        </div>
      </Dialog>
      <form onSubmit={handleSubmit}>
        <InputRowEmail
          value={formData.email}
          error={errors.email}
          onChange={handleChange}
          id="id"
        />
        <InputRowPassword
          value={formData.password}
          error={errors.password}
          onChange={handleChange}
          id="passId"
        />
        {/* <Textarea message={formData.message} error={errors.textarea} /> */}
        <RadioButtonSingleRow
          error={errors.radio}
          onChange={handleChange}
          name={formData.name}
          id={formData.radioId.id1}
        />
        <RadioButtonSingleRow
          error={errors.radio}
          onChange={handleChange}
          name={formData.name}
          id={formData.radioId.id2}
        />
        <CheckboxRow error={errors.checkbox} onChange={handleChange} id={formData.checkboxId} />
        <SubmitButton text={buttonStyle.text} />
      </form>
      <Button variant="outlined" onClick={() => setIsModalOpen(true)}>
        Right modal
      </Button>
      <RightModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>Ֆիլտրել</h2>
        <Accordion items={items} />
      </RightModal>
    </Panel>
  )
}

export default Form
