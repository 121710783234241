import React, { useState } from 'react'
import styles from './accordion.module.css'
interface AccordionItem {
  title: string
  content: string
}

interface AccordionProps {
  items: AccordionItem[]
}

const Accordion: React.FC<AccordionProps> = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const onTitleClick = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index)
  }

  const renderedItems = items.map((item, index) => {
    const isActive = index === activeIndex
    const contentClass = isActive ? 'activeAccordion' : ''

    return (
      <div key={index} className={styles.accordionItem}>
        <div
          className={`title ${contentClass} ${styles.accordionTitleMain}`}
          onClick={() => onTitleClick(index)}
        >
          <p className={styles.accordionTitle}>
            <span className={contentClass}>{item.title}</span>
          </p>
          <img
            alt={'arrow-icon'}
            src="/images/accordionArrow.svg"
            className={`${styles.arrow} ${contentClass}`}
          />
        </div>
        <div className={`accordionContent ${contentClass} ${styles.content}`}>
          {isActive && item.content}
        </div>
      </div>
    )
  })

  return <div className="accordion">{renderedItems}</div>
}

export default Accordion
