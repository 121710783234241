import React from 'react'
import styles from './LoginImg.module.css'

function LoginImg(): JSX.Element {
  return (
    <div className={styles.loginDecoration}>
      <img src="/images/union.svg" data-testid="login-img" />
      <div className={styles.decorationTitle} data-testid="decoration-title">
        <span className={styles.miniTitle}>Refresh</span>
        <h1 className={styles.title}>Your business</h1>
      </div>
    </div>
  )
}

export default LoginImg
