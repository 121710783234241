import * as React from 'react'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import './breadcrumbs.css'
import { ReactNode } from 'react'

type BreadcrumbsProps = {
  children: ReactNode
}
export default function ActiveLastBreadcrumb({ children }: BreadcrumbsProps): JSX.Element {
  return (
    <div role="presentation" className="breadcrumbs">
      <Breadcrumbs aria-label="breadcrumb">{children}</Breadcrumbs>
    </div>
  )
}
