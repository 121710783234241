import React from 'react'
import LoginImg from './LoginImg'
import styles from './Login.module.css'
import LoginForm from './LoginForm'
const App: React.FC = () => {
  return (
    <>
      <div className={styles.loginRegistration}>
        <LoginForm />
        <LoginImg />
      </div>
    </>
  )
}
export default App
