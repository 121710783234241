import React, { ReactNode, useState } from 'react'
import styles from './Header.module.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LanguageSwitcher from '../../I18n/LanguageSwitcher'

function Profile({ children }: { children: ReactNode }): JSX.Element {
  const [profileDropdown, setProfileDropdown] = useState<boolean>(false)
  const { t, i18n } = useTranslation()

  const toggleDropdown = () => {
    setProfileDropdown(!profileDropdown)
  }
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }
  return (
    <div className={styles['profile-info']}>
      <div className={styles['profile-info']} onClick={toggleDropdown}>
        <p>Jones Ferdinand</p>
        <img src={'/images/prof.svg'} alt="" />
      </div>
      {profileDropdown && (
        <div className={styles['profile-dropdown']}>
          <Link to="/profile" className={styles['pd-link']}>
            <img src="/images/puser.svg" />
            <span>Պրոֆիլ</span>
          </Link>
          <a href="" className={styles['pd-link']}>
            <img src="/images/sun_duotone.svg" />
            <span>Թեմա</span>
          </a>
          <button className={styles['pd-link']}>
            <img src="/images/signout.svg" />
            <span>Ելք</span>
          </button>
          <LanguageSwitcher />
        </div>
      )}
    </div>
  )
}
export default Profile
