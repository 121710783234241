import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import './dialog.css'
import { useTranslation } from 'react-i18next'
type ModalProps = {
  children: React.ReactNode
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))
const CustomizedDialogs: React.FC<ModalProps> = ({ children }) => {
  const [open, setOpen] = React.useState(false)
  const { t, i18n } = useTranslation()
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        {t('open')}
      </Button>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="mainDialog"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        ></IconButton>
        <DialogContent>{children}</DialogContent>
        <DialogActions className="dialogActions">
          <Button onClick={handleClose} className="dialogCloseBtn">
            {t('close')}
          </Button>
          <Button autoFocus onClick={handleClose} className="dialogSaveBtn">
            {t('ok')}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}

export default CustomizedDialogs
