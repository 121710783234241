import React from 'react'
import { FeaturesProvider } from '../FeatureToggle'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Join from '../Join'
import { NotFound } from '../Error'
import Confirm from '../Join/Confirm'
import Success from '../Join/Success'
import OAuth from '../OAuth'
import { AuthProvider } from '../OAuth/Provider'
import Dashboard from '../Dashboard'
import Form from '../UiComponent/Components/Form/FormComponent'
import Login from '../Login'

function App({ features }: { features: string[] }) {
  return (
    <FeaturesProvider features={features}>
      <AuthProvider
        authorizeUrl={process.env.REACT_APP_AUTH_URL + '/authorize'}
        tokenUrl={process.env.REACT_APP_AUTH_URL + '/token'}
        clientId="frontend"
        scope="common"
        redirectPath="/oauth"
      >
        <BrowserRouter>
          <Routes>
            <Route path={'/'} element={<Login />} />
            <Route path={`/:lang/dashboard`} element={<Dashboard />} />
            <Route path={`/:lang/form`} element={<Form />} />
            <Route path="/login" element={<Login />} />
            {features.includes('OAUTH') ? <Route path="/oauth" element={<OAuth />} /> : null}
            <Route path="/join" element={<Join />} />
            <Route path="/join/confirm" element={<Confirm />} />
            <Route path="/join/success" element={<Success />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </FeaturesProvider>
  )
}

export default App
