import React, { ReactNode } from 'react'
import styles from './Content.module.css'

function Content({ children }: { children: ReactNode }): JSX.Element {
  return (
    <section className={styles.content} style={{ height: '100vh', width: '100%' }}>
      {children}
    </section>
  )
}

export default Content
