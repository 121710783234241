import React, { ReactNode, useState } from 'react'
import styles from './Header.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'

function Notifications({ children }: { children: ReactNode }): JSX.Element {
  const NotificationItems = {
    NotificationData: [
      {
        notificationTitle: 'Նոր պատվեր Կոմպոզիցիա «THE BOX» №4 տակդիր...',
        notificationLink: '/',
        notificationStatus: 'success',
        id: 1,
      },
      {
        notificationTitle: 'Some important message here',
        notificationLink: '/',
        notificationStatus: 'standard',
        id: 2,
      },
      {
        notificationTitle: 'Some error message here (low quantity!)',
        notificationLink: '/',
        notificationStatus: 'error',
        id: 3,
      },
      {
        notificationTitle: 'Some important message here',
        notificationLink: '/',
        notificationStatus: 'standard',
        id: 4,
      },
    ],
  }
  const [notificationDropdown, setNotificationDropdown] = useState(false)
  const handleToggleotification = () => {
    setNotificationDropdown(!notificationDropdown)
  }
  return (
    <div className={styles.notify}>
      <div className={styles['notification-icon']} onClick={handleToggleotification}>
        <FontAwesomeIcon
          icon={faBell}
          style={{
            fontWeight: 900,
            color: '#434E5B',
            marginLeft: '30px',
            fontSize: '20px',
            cursor: 'pointer',
          }}
        />
        {NotificationItems.NotificationData.length !== 0 && (
          <span className={styles['notification-count']}>
            {NotificationItems.NotificationData.length}
          </span>
        )}
      </div>
      {notificationDropdown && NotificationItems.NotificationData.length !== 0 && (
        <div className={styles['notify-content']}>
          {NotificationItems.NotificationData.map((item) => (
            <a
              href={item.notificationLink}
              className={`${styles['notify-item']} ${styles[`notify-${item.notificationStatus}`]}`}
              key={item.id}
            >
              {item.notificationTitle}
            </a>
          ))}
        </div>
      )}
    </div>
  )
}

export default Notifications
