import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const LanguageLink: React.FC<{ to: string } & React.HTMLProps<HTMLAnchorElement>> = ({
  to,
  children,
  ...rest
}) => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  const language = i18n.language
  const localizedTo = `/${language}${to}`

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    navigate(localizedTo)
  }

  return (
    <a href={localizedTo} onClick={handleClick} {...rest}>
      {children}
    </a>
  )
}

export default LanguageLink
