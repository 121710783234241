import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import i18n from './i18n'
import styles from './LanguageSwitcher.module.css'
import { useNavigate, useLocation } from 'react-router-dom'
function LanguageSwitcher() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const changeLanguage = (newLanguage: string) => {
    const currentPath = location.pathname
    let newPath = currentPath
    if (currentPath.match(/^\/(hy|en|ru)\//)) {
      newPath = currentPath.replace(/^\/(hy|en|ru)\//, `/${newLanguage}/`)
    } else {
      newPath = `/${newLanguage}${currentPath}`
    }
    i18n.changeLanguage(newLanguage)
    localStorage.setItem('selectedLanguage', newLanguage)
    navigate(newPath)
  }
  const savedLanguageActive = localStorage.getItem('selectedLanguage')
  return (
    <div className={styles['drop-languages']}>
      <button
        className={savedLanguageActive === 'hy' ? styles.active : ''}
        onClick={() => changeLanguage('hy')}
      >
        HAY
      </button>
      <button
        className={savedLanguageActive === 'en' ? styles.active : ''}
        onClick={() => changeLanguage('en')}
      >
        ENG
      </button>
      <button
        className={savedLanguageActive === 'ru' ? styles.active : ''}
        onClick={() => changeLanguage('ru')}
      >
        RUS
      </button>
    </div>
  )
}

export default LanguageSwitcher
