import React, { ReactNode } from 'react'
import styles from './Header.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'

class Search extends React.Component<{ children: ReactNode }> {
  render(): React.JSX.Element {
    return (
      <div className={styles['search-icon']}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          style={{
            fontWeight: 900,
            color: '#434E5B',
            marginLeft: '30px',
            fontSize: '20px',
            cursor: 'pointer',
          }}
        />
      </div>
    )
  }
}

export default Search
