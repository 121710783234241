import React from 'react'
import Input from '../../MainInput'
import Label from '../../Label'
import InputError from '../../InputError'
import '../inputRow.css'
import { useTranslation } from 'react-i18next'

interface InputProps {
  value: string
  error: string
  id: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
function InputEmail({ value, error, onChange, id }: InputProps) {
  const { t } = useTranslation()
  return (
    <Input error={error}>
      <Label htmlFor={id} label={t('email')} />
      <input id={id} name="email" type="email" value={value} onChange={onChange} required />
      <InputError error={error} />
    </Input>
  )
}

export default InputEmail
