import React from 'react'
import Checkbox from '../MainInput'
import Label from '../Label'
import InputError from '../InputError'
import './Checkbox.css'
interface CheckboxProps {
  error: string
  id: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
function CheckboxComponent({ onChange, error, id }: CheckboxProps) {
  return (
    <Checkbox error={error}>
      <input id={id} type="checkbox" onChange={onChange} required />
      <Label htmlFor={id} label="Checkbox" />
      <InputError error={error} />
    </Checkbox>
  )
}

export default CheckboxComponent
