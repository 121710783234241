import React, { ReactNode, useEffect } from 'react'
import styles from './Panel.module.css'
import Header from '../../UiComponent/Header'
import Menu from '../../UiComponent/Menu'
import Content from '../../UiComponent/Content'

function Panel({ children }: { children: ReactNode }): JSX.Element {
  const menuData = {
    logoImgSrc: '/images/logo.svg',
    logoImgAlt: '',
    logoLink: '',
    menuItems: [
      {
        imageSrc: '/images/item1.svg',
        imageAlt: '',
        title: 'Դաշբորդ',
        link: '',
        list: [],
        id: 1,
        isDropdownOpen: false,
      },
      {
        imageSrc: '/images/catalog.svg',
        imageAlt: '',
        title: 'Կատալոգ',
        link: '',
        id: 2,
        isDropdownOpen: false,
        list: [
          {
            link: '',
            title: 'Կտրոններ',
          },
          {
            link: '',
            title: 'Ապրանքներ',
          },
          {
            link: '',
            title: 'Հաշվետվություն',
          },
          {
            link: '',
            title: 'Քարտեր',
          },
        ],
      },
      {
        imageSrc: '/images/orders.svg',
        imageAlt: '',
        title: 'Պատվերներ',
        link: '',
        id: 3,
        isDropdownOpen: false,
        list: [
          {
            link: '',
            title: 'Կտրոններ',
          },
          {
            link: '',
            title: 'Ապրանքներ',
          },
          {
            link: '',
            title: 'Հաշվետվություն',
          },
          {
            link: '',
            title: 'Քարտեր',
          },
        ],
      },
      {
        imageSrc: '/images/sales.svg',
        imageAlt: '',
        title: 'Վաճառք',
        link: '',
        id: 4,
        isDropdownOpen: false,
        list: [
          {
            link: '',
            title: 'Կտրոններ',
          },
          {
            link: '',
            title: 'Ապրանքներ',
          },
          {
            link: '',
            title: 'Հաշվետվություն',
          },
          {
            link: '',
            title: 'Քարտեր',
          },
        ],
      },
      {
        imageSrc: '/images/list-m.svg',
        imageAlt: '',
        title: 'Գույքացուցակ',
        link: '',
        id: 5,
        isDropdownOpen: false,
        list: [
          {
            link: '',
            title: 'Կտրոններ',
          },
          {
            link: '',
            title: 'Ապրանքներ',
          },
          {
            link: '',
            title: 'Հաշվետվություն',
          },
          {
            link: '',
            title: 'Քարտեր',
          },
        ],
      },
      {
        imageSrc: '/images/users.svg',
        imageAlt: '',
        title: 'Հաճախորդներ',
        link: '',
        id: 6,
        isDropdownOpen: false,
        list: [
          {
            link: '',
            title: 'Կտրոններ',
          },
          {
            link: '',
            title: 'Ապրանքներ',
          },
          {
            link: '',
            title: 'Հաշվետվություն',
          },
          {
            link: '',
            title: 'Քարտեր',
          },
        ],
      },
      {
        imageSrc: '/images/partner.svg',
        imageAlt: '',
        title: 'Գործընկերներ',
        link: '',
        id: 7,
        isDropdownOpen: false,
        list: [
          {
            link: '',
            title: 'Կտրոններ',
          },
          {
            link: '',
            title: 'Ապրանքներ',
          },
          {
            link: '',
            title: 'Հաշվետվություն',
          },
          {
            link: '',
            title: 'Քարտեր',
          },
        ],
      },
      {
        imageSrc: '/images/reports.svg',
        imageAlt: '',
        title: 'Զեկույցներ',
        link: '',
        id: 8,
        isDropdownOpen: false,
        list: [
          {
            link: '',
            title: 'Կտրոններ',
          },
          {
            link: '',
            title: 'Ապրանքներ',
          },
          {
            link: '',
            title: 'Հաշվետվություն',
          },
          {
            link: '',
            title: 'Քարտեր',
          },
        ],
      },
      {
        imageSrc: '/images/settings.svg',
        imageAlt: '',
        title: 'Կարգավորում',
        link: '',
        id: 9,
        isDropdownOpen: false,
        list: [],
      },
      {
        imageSrc: '/images/logistic.svg',
        imageAlt: '',
        title: 'Լոգիստիկա',
        link: '',
        id: 10,
        isDropdownOpen: false,
        list: [
          {
            link: '',
            title: 'Կտրոններ',
          },
          {
            link: '',
            title: 'Ապրանքներ',
          },
          {
            link: '',
            title: 'Հաշվետվություն',
          },
          {
            link: '',
            title: 'Քարտեր',
          },
        ],
      },
      {
        imageSrc: '/images/frontend.svg',
        imageAlt: '',
        title: 'Frontend',
        link: '',
        id: 11,
        isDropdownOpen: false,
        list: [],
      },
    ],
  }
  useEffect(() => {
    document.body.classList.add(styles.body)
  }, [])
  return (
    <>
      <Header>{children}</Header>
      <Menu MenuData={menuData}></Menu>
      <Content>{children}</Content>
    </>
  )
}

export default Panel
