import React, { ReactNode } from 'react'
import styles from './Header.module.css'
import CustomerLogo from './CustomerLogo'
import ClientSelector from './ClientSelector'
import Search from './Search'
import Notifications from './Notifications'
import Profile from './Profile'

function Header({ children }: { children: ReactNode }): React.JSX.Element {
  return (
    <header className={styles.header}>
      <div className={styles['header-main']}>
        <div className={styles['header-content']}>
          <div className={styles.sites}>
            <CustomerLogo>{children}</CustomerLogo>
            <ClientSelector>{children}</ClientSelector>
          </div>
          <div className={styles['header-search']}>
            <Search>{children}</Search>
            <Notifications>{children}</Notifications>
          </div>
        </div>
        <Profile>{children}</Profile>
      </div>
    </header>
  )
}

export default Header
