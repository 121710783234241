import React, { useState } from 'react'
import styles from './Menu.module.css'
import MenuItem from './MenuItem'

function Menu({ MenuData }: { MenuData: MenuData }): JSX.Element {
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null)

  const handleToggleDropdown = (itemId: number) => {
    setActiveDropdown((prevActiveDropdown) => (prevActiveDropdown === itemId ? null : itemId))
  }
  return (
    <section className={styles.menu} id="sidebar">
      <div className={styles['menu-logo']}>
        <a href={MenuData.logoLink}>
          <img src={MenuData.logoImgSrc} alt={MenuData.logoImgAlt} />
        </a>
      </div>
      <div className={styles['menu-items-section']}>
        <div className={styles.overflow}>
          {MenuData.menuItems.map((value) => (
            // eslint-disable-next-line react/jsx-key
            <div className={styles['menu-item']} key={value.title}>
              <div
                onClick={() => handleToggleDropdown(value.id)}
                className={
                  activeDropdown === value.id
                    ? styles.active + ' ' + styles['menu-link']
                    : '' + styles['menu-link']
                }
              >
                <img src={value.imageSrc} alt={value.imageAlt} className={styles['menu-icon']} />
                <p className={styles['menu-title']}>{value.title}</p>
              </div>
              {activeDropdown === value.id && value.list && (
                <div className={styles['child-menu']}>
                  <h4>{value.title}</h4>
                  {value.list.map((menuItem) => (
                    <a key={menuItem.title} href={menuItem.link}>
                      {menuItem.title}
                    </a>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Menu
