import React, { useEffect } from 'react'
import i18n from './i18n'

const LanguageInitializer: React.FC = () => {
  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage')
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage)
    } else {
      i18n.changeLanguage(i18n.language)
    }
  }, [])

  return null
}

export default LanguageInitializer
