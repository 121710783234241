import React from 'react'
import Input from '../../MainInput'
import '../inputRow.css'
import { useTranslation } from 'react-i18next'

interface InputProps {
  value: string
  error: string
  id: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
function InputSubmit({ value, error, onChange, id }: InputProps) {
  return (
    <Input error={error}>
      <input id={id} name="submit" type="submit" value={value} onChange={onChange} required />
    </Input>
  )
}

export default InputSubmit
