import React from 'react'
import Panel from '../Layout/Panel/Panel'
import { useTranslation, withTranslation } from 'react-i18next'
import Breadcrumbs from '../UiComponent/Components/Breadcrumbs/Breadcrumbs'
import LanguageLink from '../I18n/LanguageLink'
import { useParams } from 'react-router-dom'
function Dashboard(): JSX.Element {
  const { t, i18n } = useTranslation()
  const breadcrumbsLink = [
    {
      title: 'Dashboard',
      link: `/dashboard`,
      style: '',
    },
    {
      title: 'Form',
      link: `/form`,
      style: '',
    },
  ]
  return (
    <Panel>
      <Breadcrumbs>
        {breadcrumbsLink.map((item, index) => (
          <LanguageLink to={item.link} key={index}>
            {item.title}
          </LanguageLink>
        ))}
      </Breadcrumbs>
      <div className="content-header">
        <div>
          <h2>{t('title', { ns: 'dashboard' })}</h2>
        </div>
      </div>
      <p>{t('text', { ns: 'dashboard' })}</p>
    </Panel>
  )
}

export default withTranslation('dashboard')(Dashboard)
