import * as React from 'react'
import styles from './rightModal.module.css'
type ModalProps = {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
}
const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null
  }

  return (
    <>
      <div className={styles.modal}>
        <div className="modal-content">
          <button onClick={onClose} className={styles.closeModal}>
            <img src="/images/closeModal.svg" />
          </button>
          {children}
        </div>
      </div>
      <div className={`${styles.layer} ${styles.show}`} onClick={onClose}></div>
    </>
  )
}

export default Modal
