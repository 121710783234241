import React, { useState } from 'react'
import styles from '../Login.module.css'
import { Link, useNavigate } from 'react-router-dom'
import InputRowEmail from '../../UiComponent/Components/Form/InputRow/InputEmail/InputEmail'
import InputRowPassword from '../../UiComponent/Components/Form/InputRow/InputPassword/InputPassword'
import UniversalComponent from '../../UiComponent/Components/UniversalComponent'
import InputRowSubmit from '../../UiComponent/Components/Form/InputRow/InputSubmit/InputSubmit'

// login: system@cf5.io pass: 41N0psGx3JvInVuk
const LoginForm: React.FC = () => {
  const [buttonStyle, setbuttonStyle] = useState({
    text: 'Submit',
    plus: 'plus',
  })
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    message: 'Message',
    agree: '',
    name: 'radioName',
    submit: 'Log in',
    emailId: 'emailId',
    passwordId: 'passwordId',
    btnId: 'btnId',
  })
  const [errors, setErrors] = useState<Record<string, string>>({})
  const navigate = useNavigate()
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const input = event.currentTarget
    setFormData({
      ...formData,
      [input.name]: input.type === 'checkbox' ? input.checked : input.value,
    })
  }
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()
    navigate('hy/dashboard')
  }
  return (
    <form className={styles.loginForm} method="post" onSubmit={handleSubmit}>
      <div>
        <img src="images/logo.svg" className={styles.logo} />
      </div>
      <div className={styles.formSection}>
        <UniversalComponent>
          <h3 className={styles.loginTitle}>Log in</h3>
          <p className={styles.paragraph}>Enter your email and password below</p>
        </UniversalComponent>
        <div className={styles.inputItem}>
          <InputRowEmail
            value={formData.email}
            error={errors.email}
            onChange={handleChange}
            id={formData.emailId}
          />
        </div>
        <div className={styles.inputItem}>
          <InputRowPassword
            id={formData.passwordId}
            value={formData.password}
            error={errors.password}
            onChange={handleChange}
          />
        </div>
        <Link to="/join" data-testid="join-link" className={styles.forgotPassword}>
          Forgot password?
        </Link>
        <InputRowSubmit
          id={formData.btnId}
          value={formData.submit}
          error={errors.submit}
          onChange={handleChange}
        />
        <UniversalComponent>
          <div className={styles.signInSection}>
            <span>Don’t have an account?</span>
            <Link to="/resgistration" data-testid="join-link" className={styles.signInLink}>
              Sign in
            </Link>
          </div>
        </UniversalComponent>
      </div>
    </form>
  )
}
export default LoginForm
