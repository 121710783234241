import React, { useState } from 'react'
import styles from './Button.module.css'
import ButtonRow from '../Button'
interface SubmitButtonProps {
  text: string
}

function SubmitButton({ text }: SubmitButtonProps) {
  const [buttonActive, setButtonActive] = useState<boolean>(true)
  return (
    <ButtonRow>
      <button
        type="submit"
        className={text === 'plus' ? styles.btnPlus : styles.button}
        disabled={!buttonActive}
      >
        {text === 'plus' ? <img src="/images/buttonPlus.svg" alt={'button-plus-image'} /> : text}
      </button>
    </ButtonRow>
  )
}

export default SubmitButton
