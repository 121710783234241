import React, { ReactNode } from 'react'

class CustomerLogo extends React.Component<{ children: ReactNode }> {
  render(): React.JSX.Element {
    return (
      <div>
        <img src={'/images/24.svg'} alt="" />
        <p>24 Group Console</p>
      </div>
    )
  }
}

export default CustomerLogo
