import React, { ReactNode } from 'react'
import styles from './Header.module.css'

class ClientSelector extends React.Component<{ children: ReactNode }> {
  render(): React.JSX.Element {
    return (
      <select className={styles.select}>
        <option>Բոլոր կայքերը</option>
        <option>Բոլոր կայքերը</option>
      </select>
    )
  }
}

export default ClientSelector
