import React, { ReactNode } from 'react'

type InputProps = {
  error: string | null
  children: ReactNode
}

function Input({ children, error }: InputProps) {
  return <div className={'simpleInput' + (error ? ' has-error' : '')}>{children}</div>
}

export default Input
