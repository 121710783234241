import React, { ReactNode } from 'react'

type ComponentProps = {
  children: ReactNode
}

function Component({ children }: ComponentProps) {
  return <div className={'uniComponent'}>{children}</div>
}

export default Component
