import React from 'react'
import RadioButton from '../MainInput'
import Label from '../Label'
import InputError from '../InputError'
import './RadioButton.css'
interface RadioProps {
  error: string
  name: string
  id: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
function RadioButtonSingleComponent({ name, onChange, error, id }: RadioProps) {
  return (
    <div data-testid="radio-group">
      <RadioButton error={error}>
        <input id={id} type="radio" onChange={onChange} required name={name} />
        <Label htmlFor={id} label="Radio 1" />
        <InputError error={error} />
      </RadioButton>
    </div>
  )
}

export default RadioButtonSingleComponent
